<template>
    <div>
        <!-- Header -->
        <HeaderRetribusi />

        <!-- panel -->
        <panel title="List Kode Rekening" class="panel panel-success">
            <!-- Tools -->
            <div class="pl-4 py-2 shadow0-sm border">
                <span class="px-2"
                    ><b-button variant="primary" @click="reload"
                        ><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button
                    ></span
                >
            </div>
            <!-- end of tools-->

            <!-- Table -->
            <div>
                <!-- filter section -->
                <b-row>
                    <b-col md="6" class="mb-3">
                        <b-form-group
                            :label-cols="3"
                            label="Filter By"
                            class="mb-0 my-1"
                        >
                            <b-input-group size="md">
                                <b-form-select
                                    v-model="filterBy"
                                    :options="fieldOptions"
                                    @change="changeFilterBy()"
                                >
                                    <option slot="first" :value="'all'"
                                        >All Data</option
                                    >
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label-cols="3"
                            label="Filter"
                            class="mb-0"
                            description="Type to Search or Click Clear to Stop Searching "
                        >
                            <b-input-group size="md">
                                <b-form-input
                                    v-model="filter"
                                    placeholder="Type to Search"
                                    debounce="500"
                                />
                                <b-input-group-append>
                                    <b-btn
                                        :disabled="!filter"
                                        @click="filter = ''"
                                        variant="info"
                                        ><i class="fa fa-sync"></i> Clear</b-btn
                                    >
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" class="mt-1">
                        <b-form-group
                            :label-cols="3"
                            label="Sort"
                            class="mb-0 my-1"
                        >
                            <b-input-group size="md">
                                <b-form-select
                                    v-model="sortBy"
                                    :options="fieldOptions"
                                >
                                    <option slot="first" :value="null"
                                        >-- none --</option
                                    >
                                </b-form-select>
                                <b-form-select
                                    :disabled="!sortBy"
                                    v-model="sortDesc"
                                    slot="append"
                                >
                                    <option :value="false">Asc</option>
                                    <option :value="true">Desc</option>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <!-- end filter section -->
                <!-- end Tools & Search -->

                <!-- Data Table & Pagination -->
                <b-table
                    show-empty
                    small
                    id="masTable"
                    ref="masTable"
                    head-variant="light"
                    :hover="true"
                    :busy.sync="isBusy"
                    :items="myGetData"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    responsive
                    bordered
                >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>

                    <template v-slot:table-colgroup="scope">
                        <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{
                                width: field.key === 'actions' ? '105px' : '',
                            }"
                        />
                    </template>

                    <template #cell(ref_rek_1)="data">
                        {{
                            data.item.ref_rek_5.ref_rek_4.ref_rek_3.ref_rek_2
                                .ref_rek_1.nama
                        }}
                    </template>

                    <template #cell(ref_rek_2)="data">
                        {{
                            data.item.ref_rek_5.ref_rek_4.ref_rek_3.ref_rek_2
                                .nama
                        }}
                    </template>

                    <template #cell(ref_rek_3)="data">
                        {{ data.item.ref_rek_5.ref_rek_4.ref_rek_3.nama }}
                    </template>

                    <template #cell(ref_rek_4)="data">
                        {{ data.item.ref_rek_5.ref_rek_4.nama }}
                    </template>

                    <template #cell(ref_rek_5)="data">
                        {{ data.item.ref_rek_5.nama }}
                    </template>

                    <!-- button actions -->
                    <template v-slot:cell(actions)="data">
                        <b-dropdown
                            split
                            class="d-flex align-item-center"
                            size="sm"
                            variant="info"
                        >
                            <template #button-content>
                                <i class="fa fa-cogs pr-1"></i> Aksi
                            </template>
                            <b-dropdown-item @click="editButton(data.item.id)"
                                ><i class="fa fa-edit"></i>
                                Edit</b-dropdown-item
                            >
                            <b-dropdown-item
                                @click="swalNotification('error', data.item.id)"
                                ><i class="fa fa-trash-alt"></i>
                                Hapus</b-dropdown-item
                            >
                        </b-dropdown>
                    </template>
                    <!-- end button actions -->
                </b-table>
                <div class="divider"></div>

                <!-- pagination section -->
                <b-row>
                    <div class="divider"></div>
                    <b-col md="6" class="my-0">
                        <b-form-group
                            :label-cols="2"
                            label="Per page"
                            class="mb-0"
                        >
                            <b-form inline>
                                <b-form-select
                                    :options="pageOptions"
                                    style="width: 100px;"
                                    v-model="perPage"
                                />
                                <label class="ml-1 mr-1">/</label>
                                <b-form-input
                                    :disabled="true"
                                    v-model="totalRows"
                                    style="width: 100px;"
                                    class="text-right"
                                />
                                <label class="ml-1">Rows</label>
                            </b-form>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" class="my-0">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="md"
                            class="my-0 "
                        ></b-pagination>
                    </b-col>
                </b-row>
                <!-- end pagination section -->
            </div>
            <!-- end of table -->
        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

// load component header retribusi
import HeaderRetribusi from "@/components/npwr/HRetribusi.vue";
import axios from "axios";

export default {
    components: {
        HeaderRetribusi,
    },
    data() {
        return {
            // tools in table
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            testFilter: "",
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            // field in table
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "ref_rek_1",
                    label: "Rekening 1",
                    sortable: true,
                },
                {
                    key: "ref_rek_2",
                    label: "Rekening 2",
                    sortable: true,
                },
                {
                    key: "ref_rek_3",
                    label: "Rekening 3",
                    sortable: true,
                },
                {
                    key: "ref_rek_4",
                    label: "Rekening 4",
                    sortable: true,
                },
                {
                    key: "ref_rek_5",
                    label: "Rekening 5",
                    sortable: true,
                },
                {
                    key: "kode",
                    label: "Kode Rekening 6",
                    sortable: true,
                },
                {
                    key: "nama",
                    label: "Rekening 6",
                    sortable: true,
                },
            ],
            rek1: [],
        };
    },
    mounted() {
        this.getRek1();
    },
    methods: {
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/referensi/rekening-6", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    rekening1: true,
                    rekening2: true,
                    rekening3: true,
                    rekening4: true,
                    rekening5: true,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({name: 'Login'})
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        // refresh button
        reload() {
            this.$refs.masTable.refresh();
        },
        // ketika filter diisi data
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },

        // data rekening 1
        getRek1() {
            axios
                .get("/api/referensi/rekening-1")
                .then((response) => {
                    this.rek1 = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("ER_token");
                        this.$router.push({name: 'Login'})
                    }
                });
        },
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
    },
};
</script>
